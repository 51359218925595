import React from "react";
import { Container, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Switch, Route } from "react-router-dom";
import { useTranslation } from "react-i18next";
import IconDue from "./assets/icon-due.svg";
import IconLegal from "./assets/icon-legal.svg";
import IconMl from "./assets/icon-ml.svg";
import IconNsl from "./assets/icon-nsl.svg";
import IconJournalists from "./assets/icon-journalists.svg";
import ForJournalistsVideoHK from "./assets/MasterKYRHK_HKVO_HKSub.mp4";
import ForJournalistsVideoEng from "./assets/MasterKYRHK_EnglishVO_EngSub.mp4";

function Banner() {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const isHK = i18n.language === "zh";
  const videoSrc = isHK ? ForJournalistsVideoHK : ForJournalistsVideoEng;

  return (
    <>
      <div style={{ backgroundColor: "#F7F7F8" }}>
        <Container maxWidth="xl">
          <Container className={classes.containerStyles}>
            <Switch>
              <Route path="/:lang/national-security-law">
                <div className={classes.textBox}>
                  <div className={classes.icon}>
                    <img src={IconNsl} alt={t("National Security Law")} />
                  </div>
                  <Typography className={classes.engText}>
                    {t("National Security Law")}
                  </Typography>
                </div>
                <div className={classes.descBox}>
                  <Typography
                    className={classes.descText}
                    dangerouslySetInnerHTML={{
                      __html: t(
                        "Understanding the National Security Law, what offences it covers, who can be charged under it, how it is enforced and the ways in which it has been used since it was introduced is important to ensure you do not fall foul of the legislation."
                      ),
                    }}
                  />
                </div>
              </Route>
              <Route path="/:lang/money-laundering">
                <div className={classes.textBox}>
                  <div className={classes.icon}>
                    <img src={IconMl} alt={t("Money Laundering")} />
                  </div>
                  <Typography className={classes.engText}>
                    {t("Money Laundering")}
                  </Typography>
                </div>
                <div className={classes.descBox}>
                  <Typography
                    className={classes.descText}
                    dangerouslySetInnerHTML={{
                      __html: t(
                        "Financial transactions are increasingly under strict scrutiny in Hong Kong. Understand the money laundering legislation to ensure you stay on the right side of the law."
                      ),
                    }}
                  />
                </div>
              </Route>
              <Route path="/:lang/due-process">
                <div className={classes.textBox}>
                  <div className={classes.icon}>
                    <img src={IconDue} alt={t("Due Process")} />
                  </div>
                  <Typography className={classes.engText}>
                    {t("Due Process")}
                  </Typography>
                </div>
                <div className={classes.descBox}>
                  <Typography
                    className={classes.descText}
                    dangerouslySetInnerHTML={{
                      __html: t(
                        "Understand your legal rights and obligations in police encounters and while navigating the criminal justice system in Hong Kong. Know what to expect and act in your best interests if you are stopped, searched, arrested or charged."
                      ),
                    }}
                  />
                </div>
              </Route>
              <Route path="/:lang/legal-aid">
                <div className={classes.textBox}>
                  <div className={classes.icon}>
                    <img src={IconLegal} alt={t("Legal aid")} />
                  </div>
                  <Typography className={classes.engText}>
                    {t("Legal aid")}
                  </Typography>
                </div>
                <div className={classes.descBox}>
                  <Typography
                    className={classes.descText}
                    dangerouslySetInnerHTML={{
                      __html: t(
                        "Everything you need to know about getting free or subsidised legal advice, representation or other forms of legal assistance in Hong Kong."
                      ),
                    }}
                  />
                </div>
              </Route>
              <Route path="/:lang/guidance-for-journalists">
                <div className={classes.textBox}>
                  <div className={classes.icon}>
                    <img src={IconJournalists} alt={t("For journalists")} />
                  </div>
                  <Typography className={classes.engText}>
                    {t("Guidance for journalists in Hong Kong")}
                  </Typography>
                </div>
                <div className={classes.descBox}>
                  <Typography
                    className={classes.descText}
                    dangerouslySetInnerHTML={{
                      __html: t(
                        "The Beijing-imposed National Security Law has had a profound and far-reaching impact on Hong Kong’s media environment. Used alongside the colonial-era sedition law that had been dormant since the 1970s, the legislation has limited press freedom and freedom of speech."
                      ),
                    }}
                  />
                  <Typography
                    className={classes.descText}
                    dangerouslySetInnerHTML={{
                      __html: t(
                        "Three prominent publications were forced to close for their editorial stance. Several other outlets have shuttered, citing security concerns. Those still operating self-censor or tone down their reporting on sensitive political matters, fearing repercussions under the new law."
                      ),
                    }}
                  />
                  <Typography
                    className={classes.descText}
                    dangerouslySetInnerHTML={{
                      __html: t(
                        "This has had a chilling effect on reporters who now fear falling foul of legislation that authorities are using to silence dissent and criticism. This guidance helps journalists navigate their legal rights when interacting with the police in public, accessing public records, and being served a search warrant or production order."
                      ),
                    }}
                  />
                </div>
                <div className={classes.videoBox}>
                  <video
                    key={videoSrc}
                    className={classes.videoEmbed}
                    width="818"
                    height="464"
                    controls
                    preload="metadata"
                  >
                    <source src={videoSrc} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>
              </Route>
            </Switch>
          </Container>
        </Container>
      </div>
      <Switch>
        <Route path="/:lang/guidance-for-journalists">
          <div className={classes.videoSpacer}></div>
        </Route>
      </Switch>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  textBox: {
    flex: "1",
    display: "flex",
    justifyContent: "flex-start",
    gap: "24px",
    [theme.breakpoints.down("sm")]: {
      // paddingTop: '90px',
      width: "100%",
    },
  },
  descBox: {
    flex: "1",
    display: "flex",
    flexDirection: "column",
    gap: "24px",
  },
  videoBox: {
    width: "100%",
    paddingTop: "44px",
    minHeight: "200px",
    position: "relative",
    [theme.breakpoints.down("xs")]: {
      paddingTop: "18px",
      minHeight: "60px",
    },
  },
  videoEmbed: {
    position: "absolute",
    left: "50%",
    transform: "translateX(-50%)",
    maxWidth: "100%",
    [theme.breakpoints.down("xs")]: {
      height: "54vw",
    },
  },
  videoSpacer: {
    height: "232px",
    backgroundColor: "white",
    [theme.breakpoints.down("xs")]: {
      height: "40vw",
    },
  },
  engText: {
    fontFamily: "Manrope",
    fontWeight: 900,
    fontSize: "45px",
    lineHeight: 1.2,
    color: theme.palette.text.primary,
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      fontSize: "35px",
      width: "100%",
    },
  },
  descText: {
    fontFamily: "Manrope",
    fontStyle: "normal",
    fontSize: "17px",
    lineHeight: "1.5",
    fontWeight: "500",
    letterSpacing: "-0.01em",
    color: theme.palette.text.primary,
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
      width: "100%",
    },
  },
  icon: {
    width: "50px",
    minWidth: "50px",
    marginTop: "4px",
    "& > img": {
      width: "50px",
      height: "50px",
      maxWidth: "100%",
      maxHeight: "100%",
    },
  },
  containerStyles: {
    display: "flex",
    justifyContent: "space-around",
    flexWrap: "wrap",
    // overflow: "hidden",
    padding: "100px 24px",
    gap: "56px",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      padding: "60px 0px",
      gap: "38px",
    },
    [theme.breakpoints.up("lg")]: {
      maxWidth: "1408px",
    },
  },
}));

export default Banner;
